import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/circleci/choice/src/components/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "isBox": true,
      "flipKey": 107
    }}><h1 parentName="div">{`Choice`}</h1>
      <div className="subtitle">A Research Project for School Choice in Berlin primary schools introduces itself.</div>
      <p parentName="div">{`Dear parents, dear guardians,`}</p>
      <p parentName="div">{`With the enrolment of your child in primary school, a new, exciting and important time is beginning for you and your child from September 2015. As part of our research project, we are interested in who and what influenced your choice of school and what you expect of your child's future school. We would like to introduce the research project to you in brief below.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 108
    }}>
      <h1 parentName="div">{`What is choice?`}</h1>
      <p parentName="div"><em parentName="p">{`Choice. School selection and socio-cultural matching`}</em>{` is a research project financed by the `}<a parentName="p" {...{
          "href": "http://www.dfg.de/"
        }}>{`Deutsche Forschungsgemeinschaft (German Research Foundation, DFG)`}</a>{` that runs until autumn 2017. It is being conducted in primary schools by educational researchers from the `}<a parentName="p" {...{
          "href": "https://www.hu-berlin.de/"
        }}>{`Humboldt University in Berlin`}</a>{` and the `}<a parentName="p" {...{
          "href": "http://www.uni-muenster.de/de/"
        }}>{`University of Münster`}</a>{`.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 109
    }}>
      <h1 parentName="div">{`What is Choice investigating?`}</h1>
      <p parentName="div">{`The study is addressing the following questions: Who chooses which school for their child and why? Why is no choice made? What expectations do parents have of their child's future school? The project therefore surveys the motives behind parents' choice of school in the context of the features of school quality that they perceive (e.g. the atmosphere in school or the teaching methods) and other factors related to the choice of school (e.g. the distance to the school). The importance of these features of school quality is also being investigated for all parents in relation to their child's future school. Moreover, the role that schools and school managements play in the process of school selection is also being examined, in other words: how do schools select their pupils?`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 110
    }}>
      <h1 parentName="div">{`What is the aim of the study?`}</h1>
      <p parentName="div">{`The aim of the study is to show how the educational ideas of parents ñ relating to the school ñ match with the various types of schools and school profiles with their different pedagogical, religious and social orientations and programmes, and to discuss the effects of this matching for the development of the individual school and the school system as a whole.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 111
    }}>
      <h1 parentName="div">{`How is the study being carried out?`}</h1>
      <p parentName="div">{`In the course of the study, parents and school managements throughout Berlin are being surveyed both in writing and verbally. The surveys are taking place at a total of 2000 state and private primary schools in 2015 and 2016 and are being conducted anonymously either online or by the project staff.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 112
    }}>
      <h1 parentName="div">{`What is new about this study?`}</h1>
      <p parentName="div">{`The study is the first academic investigation that focuses on parents' motives for choosing schools and their expectations of a "good school", and compares the similarities and difference in parental motivation for choosing schools with pupil selection processes in state and private schools.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 113
    }}>
      <h1 parentName="div">{`What happens to my data?`}</h1>
      <p parentName="div">{`The survey is voluntary and you remain entirely anonymous during your participation in the survey. All your details are treated in strict confidence and are only evaluated for academic purposes. The data will not be passed on to third parties. The study is being financed by public funds and is being run in full compliance with Berlin's data protection legislation, the guidelines of the Fachgesellschaften Erziehungswissenschaft (Academic Societies for Educational Studies) and the Deutsche Forschungsgemeinschaft (DFG).`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 114
    }}>
      <h1 parentName="div">{`What do you and your child’s school get out of the study?`}</h1>
      <p parentName="div">{`In addition to the results of the research project, which will be made available to you and the school, we are raffling off book and multimedia vouchers with a total value of €4000 among all of the participating schools.`}</p>
    </div><div {...{
      "isBox": true,
      "flipKey": 115
    }}>
      <h1 parentName="div">{`Do you have any more questions?`}</h1>
      <p parentName="div">{`If so, please feel free to write to us or give us a call.`}</p>
      <p parentName="div">{`Prof. Dr. Thomas Koinzer`}<br /><br parentName="p"></br>{`
`}{`Humboldt-Universität zu Berlin`}<br /><br parentName="p"></br>{`
`}{`Kultur-, Sozial- und Bildungswissenschaftliche Fakultät`}<br /><br parentName="p"></br>{`
`}{`Institut für Erziehungswissenschaften`}<br /><br parentName="p"></br>{`
`}{`Systematische Didaktik und Unterrichtsforschung`}<br /><br parentName="p"></br>{`
`}{`Unter den Linden 6`}<br /><br parentName="p"></br>{`
`}{`10099 Berlin`}<br /><br parentName="p"></br>{`
`}<a parentName="p" {...{
          "href": "mailto:thomas.koinzer@hu-berlin.de"
        }}>{`thomas.koinzer@hu-berlin.de`}</a><br /><br parentName="p"></br>{`
`}{`Phone 030/20931633`}</p>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      